// Application variables
export const REACT_APP_CLIENT_URL: string =
  process.env.REACT_APP_CLIENT_URL || 'https://app.seedtalent.com';
export const REACT_APP_API_URL: string =
  process.env.REACT_APP_API_URL || 'https://api.seedtalent.com';
export const REACT_APP_POS_IMPORTER_API_URL: string =
  process.env.REACT_APP_POS_IMPORTER_API_URL ||
  'https://pos-api-staging.seedtalent.com';
export const NODE_ENV: string = process.env.NODE_ENV || 'development';
export const REACT_APP_ENABLE_TIME_BASE_REPORT: boolean =
  process.env.REACT_APP_ENABLE_TIME_BASE_REPORT === 'true';

// AWS variables
export const REACT_APP_CLOUDFRONT_URL =
  process.env.REACT_APP_CLOUDFRONT_URL ||
  'https://darave5obsjrz.cloudfront.net';

// Third-Party variables
export const REACT_APP_HRB_S3_URL =
  'https://embedded.skillxchanges.net/assets/index.js';

export const REACT_APP_HRB_CSS_URL =
  'https://embedded.skillxchanges.net/assets/index.css';

export const REACT_APP_HRB_DOMAIN_ID = 'c8928447-21d4-44bf-8d46-0bc26f1c2b18';

// SENTRY API KEY
export const REACT_APP_SENTRY_DSN: string =
  process.env.REACT_APP_SENTRY_DSN ||
  'https://391c5151fbdf4c76908b82560625eae7@o1291327.ingest.sentry.io/6512750';

// Cryptography
export const PRIVATE_KEY: string = process.env.PRIVATE_KEY || 'SuperSecret!';
