import { useState, useEffect } from 'react';

import { EditIcon } from 'material-icons';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';
import { Button } from 'components/buttons';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import ManageCommunities from '../dialogs/ManageCommunities';

import httpRoutes from 'utils/httpRoutes';
import {useAuthState} from '../../../providers/AuthProvider/hooks/useAuthState';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Community Name',
  },
];

const BundleCommunitiesGrid = ({
  bundleId,
  bundleName,
}: {
  bundleId: string;
  bundleName: string;
}) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();
  const {
    authState: { user, isAdmin },
  } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    getBundleCommunities();
  }, [filters, state]);

  const getBundleCommunities = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.bundles.getBundleCommunities({
          bundleId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const showManageCommunitiesDialog = () => {
    showDialog({
      content: (
        <ManageCommunities
          onSuccess={getBundleCommunities}
          bundleId={bundleId}
          bundleName={bundleName}
        />
      ),
      size: 'md',
    });
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Community Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 3,
    },
    {
      headerName: 'Community Type',
      field: 'type',
      sortable: true,
      filterable: false,
      flex: 2,
    },
    {
      headerName: 'Total Learners',
      field: 'totalLearners',
      sortable: true,

      flex: 0.5,
    },
  ];

  return (
    <PageContainer
      showBackButton={false}
      pageAction={
      (isAdmin || user.managerOrganizationId) &&
        <Button
          label="Manage Communities"
          onClick={showManageCommunitiesDialog}
          startIcon={<EditIcon />}
        />
      }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const BundleCommunities = ({
  bundleId,
  bundleName,
}: {
  bundleId: string;
  bundleName: string;
}) => (
  <ControlledDataGrid>
    <BundleCommunitiesGrid bundleId={bundleId} bundleName={bundleName} />
  </ControlledDataGrid>
);

export default BundleCommunities;
