import React, { useEffect, useState } from 'react';
import { SelectFilter } from '../index';
import { useCallService } from '../../../../hooks';
import { ISelectOptionProps } from '../../../formFields/MultipleSelectCheckmarksFormField';
import httpRoutes from '../../../../utils/httpRoutes';

interface CountrySelectorProps {
  onCountryChange: any;
  onChange: any;
}

export const CountryFilter = (props: CountrySelectorProps) => {
  const initialCountries = { value: '0', label: 'All countries' };
  const { callService } = useCallService();
  const [countries, setCountries] = useState<ISelectOptionProps[]>([
    initialCountries,
  ]);
  const [selectedCountryId, setSelectedCountryId] = useState<number>(0);

  const getCountries = async () => {
    const { response } = await callService({
      resource: httpRoutes.location.getCountries(),
    });

    if (response) {
      const countriesResponse = response.map((item: any) => {
        return {
          value: item.id,
          label: `${item.name}`,
        };
      });
      countriesResponse.unshift(initialCountries);
      setCountries(countriesResponse);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const handleCountryChange = (selectedCountryId: number) => {
    setSelectedCountryId(selectedCountryId);
    props.onCountryChange(selectedCountryId);
    props.onChange({
      countryId: selectedCountryId != 0 ? selectedCountryId : undefined,
    });
  };

  return (
    <SelectFilter
      id={'countryId'}
      options={countries}
      onChange={handleCountryChange}
      label={'Country'}
      value={selectedCountryId}
    ></SelectFilter>
  );
};
