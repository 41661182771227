import { useState, useEffect } from 'react';

import { Controller } from 'react-hook-form';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Box, TextField } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export type ISelectOptionProps = {
  value: string;
  label: string;
  abbr?: string;
};

interface IMultipleSelectCheckmarksFormFieldProp {
  name: string;
  label: string;
  control: any;
  options: ISelectOptionProps[];
  disabled?: boolean;
  limitTags?: number;
  isSearchable?: boolean;
  color?: string;
  onChange?: any;
}

const joinSelectedLabels = (
  selected: string[],
  options: ISelectOptionProps[],
  limitTags: number
) => {
  const limit = limitTags !== 0 ? limitTags - 1 : 0;

  const selectedLabels = options
    .filter((option) => selected.includes(option.value))
    .map((option) => option.label);

  if (selected.length >= limitTags) {
    const slicedSelectedLabels = selectedLabels.slice(0, limit);

    return slicedSelectedLabels.join(', ') + `+${selected.length - limit}`;
  }

  return selectedLabels.join(', ');
};

const MultipleSelectCheckmarksFormField = ({
  name,
  label,
  control,
  options,
  disabled = false,
  limitTags = 3,
  isSearchable = true,
  color = '#56C596',
  onChange,
}: IMultipleSelectCheckmarksFormFieldProp) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] =
    useState<ISelectOptionProps[]>(options);

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setSearchResults(options);
  }, [options]);

  useEffect(() => {
    const results = options.filter((option: ISelectOptionProps) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSearchResults(results);
  }, [searchTerm]);

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, value = [] } = field;
        return (
          <FormControl sx={{ width: '100%' }}>
            <InputLabel
              sx={{
                '&.Mui-focused': { color: color },
              }}
            >
              {label}
            </InputLabel>
            <Select
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: color,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: color,
                },
              }}
              disabled={disabled}
              labelId="multiple-checkbox-label"
              id="multiple-checkbox"
              multiple
              value={value}
              onChange={(e) => {
                fieldOnChange(e);
                if(onChange) onChange(e);
              }}
              input={
                <OutlinedInput label="Choose stores to assign data Driven learning" />
              }
              renderValue={(selected) => {
                return joinSelectedLabels(selected, options, limitTags);
              }}
              MenuProps={MenuProps}
            >
              <Box
                sx={{
                  width: '50%',
                  p: 1,
                }}
                onClickCapture={stopImmediatePropagation}
                onKeyDown={(e) => e.stopPropagation()}
              >
                {isSearchable && (
                  <TextField
                    key="search"
                    label="Search"
                    sx={{
                      background: 'white',
                      '& .MuiInputLabel-root': {
                        '&.Mui-focused': { color: color },
                      },
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: color,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: color,
                        },
                      },
                    }}
                    autoFocus
                    onChange={handleSearchChange}
                    value={searchTerm}
                    id="outlined-size-small"
                    size="small"
                  />
                )}
              </Box>
              {searchResults.map((option: ISelectOptionProps) => {
                const isChecked = value ? !!value.find((selected: any) => selected === option.value) : false;

                return (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      sx={{
                        '& .MuiSvgIcon-root': {
                          borderRadius: 6,
                        },
                        color: color,
                        '&.Mui-checked': {
                          color: color,
                        },
                      }}
                      checked={isChecked}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};

export default MultipleSelectCheckmarksFormField;
