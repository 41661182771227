import { useState, useEffect } from 'react';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CommunityIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Community Name',
  },
];

const CommunitiesGrid = ({ organizationId }: { organizationId: string }) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    getOrganizationCommunities();
  }, [filters, state]);

  const getOrganizationCommunities = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.organizations.getOrganizationGroupsPaginated({
          organizationId: organizationId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
  ];

  return (
    <PageContainer
      showBackButton={false}
      title="Organization Communities"
      icon={<CommunityIcon sx={{ fontSize: '24px' }} />}
      isSecondary
      elevation={0}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const Communities = ({ organizationId }: { organizationId: string }) => (
  <ControlledDataGrid>
    <CommunitiesGrid organizationId={organizationId} />
  </ControlledDataGrid>
);

export default Communities;
