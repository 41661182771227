import { Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  MultipleSelectCheckmarksFormField,
  SelectFormField,
} from '../formFields';
import { useCallService } from '../../hooks';
import httpRoutes from '../../utils/httpRoutes';
import { ISelectOptionProps } from '../formFields/MultipleSelectCheckmarksFormField';

interface StateSelectorProps {
  countryId?: number | number[];
  control: any;
  errors?: any;
  multiSelect?: boolean;
  name?: string;
  sx?: any;
}

export const StateSelector = (props: StateSelectorProps) => {
  const { callService } = useCallService();
  const [states, setStates] = useState<ISelectOptionProps[]>([]);

  const getStates = async () => {
    if (props.countryId) {
      const { response } = await callService({
        resource: httpRoutes.location.getStatesByCountryId(props.countryId),
      });

      if (response) {
        setStates(
          response.map((item: any) => {
            return {
              value: item.id,
              label: `${item.name} (${item.code})`,
            };
          })
        );
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(props.countryId)) {
      if (props.countryId.length > 0) {
        getStates();
      } else {
        setStates([]);
      }
    } else if (props.countryId) {
      getStates();
    } else {
      setStates([]);
    }
  }, [props.countryId]);

  const multiSelector = (
    <MultipleSelectCheckmarksFormField
      name={props.name || 'states'}
      limitTags={3}
      options={states}
      control={props.control}
      label="Select all states"
      disabled={!states.length}
    />
  );

  const singleSelector = (
    <SelectFormField
      name={props.name || 'stateId'}
      control={props.control}
      label="State"
      errors={props.errors?.stateId}
      options={states}
      margin="dense"
      disabled={!states.length}
      selectSx={props.sx}
    />
  );

  if (props.multiSelect) {
    return (
      <Controller
        name={props.name || 'states'}
        control={props.control}
        render={() => multiSelector}
      />
    );
  }

  return (
    <Controller
      name={props.name || 'stateId'}
      control={props.control}
      render={() => singleSelector}
    />
  );
};
