import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { AddIcon, EditIcon, OrganizationIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Create from '../dialogs/Create';
import Edit from '../dialogs/Edit';

import httpRoutes from 'utils/httpRoutes';

import { OrganizationFormInput } from '../forms/OrganizationForm';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Organization Name',
  },
];

const OrganizationsGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();

  const showCreateDialog = () => {
    showDialog({
      content: <Create onSuccess={getOrganizations} />,
      size: 'sm',
    });
  };

  const showEditDialog = (values: OrganizationFormInput) => {
    showDialog({
      content: <Edit onSuccess={getOrganizations} defaultValues={values} />,
      size: 'sm',
    });
  };

  const getOrganizations = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.organizations.getMany({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, [filters, state]);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton onClick={() => showEditDialog(params.row)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <PageContainer
      title="Organizations"
      icon={<OrganizationIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Button
          label="Create Organization"
          onClick={showCreateDialog}
          startIcon={<AddIcon />}
        />
      }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
        onRowClick={(params) => {
          navigate(`/admin/organizations/${params.id}`);
        }}
      />
    </PageContainer>
  );
};

const Organizations = () => (
  <ControlledDataGrid>
    <OrganizationsGrid />
  </ControlledDataGrid>
);

export default Organizations;
