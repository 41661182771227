import { useCallService } from 'hooks';

import Form, { CommunityFormInput } from '../../forms/CommunityForm';

import httpRoutes from 'utils/httpRoutes';

const Create = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  const { callService } = useCallService();

  const onSubmit = async (values: CommunityFormInput) => {
    console.log(values);
    const { response } = await callService({
      resource: httpRoutes.groups.create(values),
      successMessage: 'Community created successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  );
};

export default Create;
