import { useState, useEffect } from 'react';

import { useCallService } from 'hooks';

import Form, {
  CourseRecordFormRequest,
  UserTestResultFormInput,
} from '../forms/CourseRecordForm';

import { SelectOptionProps } from 'components/formFields/types';

import httpRoutes from 'utils/httpRoutes';

const EditCourseRecord = ({
  onSuccess,
  courseId,
  userId,
}: {
  onSuccess: VoidFunction;
  courseId: string;
  userId: string;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState<UserTestResultFormInput>();
  const [courseRoundsOptions, setCourseRoundsOptions] = useState<
    SelectOptionProps[]
  >([]);
  const { callService } = useCallService();

  const onSubmit = async (values: CourseRecordFormRequest) => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.updateUTRRecord(values),
      successMessage: 'Course record updated successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  useEffect(() => {
    const getCourseRounds = async () => {
      const { response } = await callService({
        resource: httpRoutes.courseRound.getCourseRoundsByCourseId(courseId),
      });

      if (response) {
        const courseRounds = response.map((round: any) => ({
          label: `${round.roundNumber} (Max ${round.attempts} attempts)`,
          value: round.id,
        }));
        setCourseRoundsOptions(courseRounds);
      }
    };

    getCourseRounds();
  }, [userId, courseId]);

  useEffect(() => {
    const getItem = async () => {
      const { response } = await callService({
        resource: httpRoutes.userManagement.getUTRRecord(courseId, userId),
      });

      if (response) {
        const _existingCertificate =
          response.course.certificate &&
          response.course.certificate.userCertificates.length > 0 &&
          Boolean(response.course.certificate?.userCertificates[0].id);

        const _existingReward =
          response.course.reward &&
          response.course.reward.rewardCodes.length > 0 &&
          Boolean(response.course.reward?.rewardCodes[0].id);

        const _defaultValues = {
          hasReward: Boolean(response.course.rewardId),
          hasCertificate: Boolean(response.course.certificateId),
          certificateCreated: _existingCertificate,
          rewardCreated: _existingReward,
          externalCourseId: response.course.externalCourseId,
          courseSourceId: response.course.courseSourceId,
          ...response,
        };
        setDefaultValues(_defaultValues);
        setIsLoading(false);
      }
    };

    getItem();
  }, [userId, courseId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Form
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        courseRoundOptions={courseRoundsOptions}
      />
    </div>
  );
};

export default EditCourseRecord;
