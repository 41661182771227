import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Box, Grid } from '@mui/material';

import { useAuthDispatcher } from 'providers/AuthProvider/hooks/useAuthDispatcher';

import useCallService from 'hooks/useCallService';

import httpRoutes from 'utils/httpRoutes';
import { validateAge } from 'utils/date';

import EmailFormField from 'components/formFields/EmailFormField2';
import TextFormField from 'components/formFields/TextFormField2';
import NumberFormField from 'components/formFields/NumberFormField2';
import BirthdayFormField from 'components/formFields/BirthdayFormField2';
import AsyncSelectFormField from 'components/formFields/AsyncSelectFormField2';
import { CheckboxFormField } from 'components/formFields';
import Button from 'components/buttons/Button';
import { LocationSelector } from '../../../../components/locationSelector/LocationSelector';
import { useState } from 'react';

export interface IFirstTimeUserFormInputs {
  id: string;
  firstName: string;
  lastName: string;
  postal: string;
  email: string;
  termsAndConditions: boolean;
  groupId?: string;
  groupName?: string;
  birthDate: string;
  stateId: number;
  countryId: number;
}

const CompleteValidationFragment = ({
  defaultValues,
  onSuccess,
}: {
  defaultValues: any;
  onSuccess: () => void;
}) => {
  const { callService } = useCallService();
  const { login } = useAuthDispatcher();
  const { organizationId } = useParams();

  const onSubmit = async (values: IFirstTimeUserFormInputs) => {
    const { response } = await callService({
      resource: httpRoutes.authentication.organization.completeSignup(values),
      successMessage: 'Success! You are now a member of Seed Talent!',
    });

    if (response) {
      login(response);
      onSuccess();
    }
  };

  const getGroups = async (value: string) => {
    if (organizationId) {
      const { response } = await callService({
        resource: httpRoutes.groups.searchByOrganizations(value, [
          organizationId,
        ]),
      });

      if (response.length > 0) {
        const _groupList = response.map(
          (item: { id: string; name: string }) => {
            return { id: item.id, label: item.name };
          }
        );

        return _groupList;
      } else {
        return [];
      }
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    stateId: Yup.string().max(255).required('State is required'),
    countryId: Yup.string().max(255).required('Country is required'),
    groupId: Yup.string().max(255).required('Group is required'),
    postal: Yup.string()
      .matches(
        /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/,
        'Please enter a valid US or Canadian postal code'
      )
      .required('Postal code is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    birthDate: Yup.string()
      .test(
        'test-birthDate',
        'You must be older than 21 years old',
        function (value) {
          if (!value) {
            return true;
          }

          const [month, day, year] = value.split('/');

          if (month.trim() && day.trim() && year.trim()) {
            const date = new Date(value);
            return validateAge(date);
          }
          return true;
        }
      )
      .required('Birth date is required'),
    termsAndConditions: Yup.boolean()
      .oneOf([true], 'The terms and conditions must be accepted.')
      .required('The terms and conditions must be accepted.'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setValue,
  } = useForm<IFirstTimeUserFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      id: defaultValues.id,
      firstName: '',
      lastName: '',
      email: defaultValues.email,
      postal: '',
      groupId: '',
      groupName: '',
      termsAndConditions: false,
      birthDate: '',
      stateId: undefined,
      countryId: undefined,
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '40px',
            lineHeight: '130%',
          }}
        >
          Complete Sign up
        </Box>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px%',
          }}
        >
          Enter your credentials to continue
        </Box>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={6}>
          <TextFormField
            name="firstName"
            control={control}
            placeholder="First Name"
            errors={errors.firstName}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            name="lastName"
            control={control}
            placeholder="Last Name"
            errors={errors.lastName}
            required
          />
        </Grid>
      </Grid>
      <LocationSelector control={control} errors={errors}></LocationSelector>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={6}>
          <NumberFormField
            name="postal"
            control={control}
            placeholder="Postal code"
            errors={errors.postal}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BirthdayFormField
            name="birthDate"
            control={control}
            placeholder="Birthday"
            errors={errors.birthDate}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailFormField
            name="email"
            control={control}
            placeholder="Email"
            errors={errors.email}
            disabled
          />
        </Grid>
        <Grid item xs={12} mb={3}>
          <AsyncSelectFormField
            name="groupId"
            control={control}
            placeholder="Search Locations"
            getOptions={getGroups}
            required
          />
        </Grid>
        <Grid mb={3} container>
          <Grid xs={6} item>
            <CheckboxFormField
              sx={{
                '.MuiFormControlLabel-label': {
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                },
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
              name="termsAndConditions"
              size="small"
              control={control}
              label="Agree to Terms & Conditions."
              errors={errors.termsAndConditions}
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            label="Sign up"
            disabled={isSubmitting || !isValid}
            sx={{
              textTransform: 'uppercase',
              fontFamily: 'Raleway',
              fontWeight: '700',
              fontSize: '20px',
              lineHeight: '24px',
              background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default CompleteValidationFragment;
