import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { AddIcon, CommunityIcon, EmailIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Create from '../dialogs/community/Create';
import Delete from '../dialogs/community/Delete';

import CommunityCampaign from 'features/communication/email/dialogs/CommunityCampaign';

import httpRoutes from 'utils/httpRoutes';

import { CommunityFormInput } from '../forms/CommunityForm';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Community Name',
  },
];

const CommunitiesGrid = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();

  const showCreateDialog = () => {
    showDialog({
      content: <Create onSuccess={onSuccess} />,
      size: 'sm',
    });
  };

  const onSuccess = async () => {
    await getCommunities();
    hideDialog();
  };

  const showDeleteDialog = (values: CommunityFormInput) => {
    if (!values.name || !values.id) {
      return;
    }

    showDialog({
      content: (
        <Delete
          onSuccess={onSuccess}
          hideDialog={hideDialog}
          communityName={values.name}
          communityId={values.id}
        />
      ),
      size: 'sm',
    });
  };

  const showEmailCampaignDialog = () => {
    showDialog({
      content: <CommunityCampaign />,
      size: 'sm',
    });
  };

  useEffect(() => {
    getCommunities();
  }, [filters, state]);

  const getCommunities = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.groups.getManyPaginated({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    // {
    //   headerName: 'Archived',
    //   field: 'archived',
    //   sortable: true,
    //   filterable: false,
    //   headerAlign: 'center',
    //   type: 'boolean',
    //   flex: 0.5,
    // },
    // {
    //   headerName: 'Action',
    //   field: 'action',
    //   filterable: false,
    //   sortable: false,
    //   flex: 0.15,
    //   ColumnComponent: (params: any) => {
    //     if (!params.row.archived) {
    //       return (
    //         <>
    //           <IconButton
    //             onClick={(e: MouseEvent<HTMLButtonElement>) => {
    //               e.stopPropagation();
    //               showDeleteDialog(params.row);
    //             }}
    //           >
    //             <DeleteIcon color="error" />
    //           </IconButton>
    //         </>
    //       );
    //     }
    //   },
    // },
  ];

  return (
    <PageContainer
      title="Communities"
      icon={<CommunityIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        isAdmin && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box>
              <Button
                label="Create Community"
                onClick={showCreateDialog}
                startIcon={<AddIcon />}
              />
            </Box>
            <Box>
              <Button
                label="Email Community"
                onClick={showEmailCampaignDialog}
                startIcon={<EmailIcon />}
              />
            </Box>
          </Box>
        )
      }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
        onRowClick={(params) => {
          isAdmin
            ? navigate(`/admin/communities/${params.id}`)
            : navigate(`/communities/${params.id}`);
        }}
      />
    </PageContainer>
  );
};

const Communities = ({ isAdmin = false }: { isAdmin?: boolean }) => (
  <ControlledDataGrid>
    <CommunitiesGrid isAdmin={isAdmin} />
  </ControlledDataGrid>
);

export default Communities;
