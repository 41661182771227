import { useState, useEffect } from 'react';

import { useCallService } from 'hooks';

import MultiStepForm from '../forms/MultiStepForm';
import { CourseBuilderFormInput } from 'types/courseTypes/CourseBuilderFormInput';

import httpRoutes from 'utils/httpRoutes';
import LoadingState from 'components/LoadingState';
import { Grid } from '@mui/material';

const Edit = ({
  onCloseModal,
  courseId,
}: {
  onCloseModal: (scormAlert: boolean) => Promise<void>;
  courseId: string;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState<CourseBuilderFormInput>();
  const { callService } = useCallService();

  const execOnCloseModal = async (values: CourseBuilderFormInput) => {
    await onCloseModal(Boolean(values.courseFileChanged));
  };

  const onSubmit = async (values: CourseBuilderFormInput) => {
    try {
      await callService({
        resource: httpRoutes.courseManagement.update(values),
        successMessage:
          values.source === 'scorm' || values.isFirstStep
            ? 'Course edited successfully!'
            : 'Course edited successfully! All your changes will be automatically saved.',
        autoHideDuration: 5000,
      });
    } catch (error: any) {
      console.error(error);
      await execOnCloseModal(values);
    }
  };

  useEffect(() => {
    const getItem = async () => {
      const { response } = await callService({
        resource: httpRoutes.courseManagement.getOne(courseId),
      });

      if (response) {
        const {
          courseMessage,
          courseStates,
          courseType,
          externalCourseId,
          ...otherValues
        } = response;

        const defaultValues = {
          ...otherValues,
          courseTypeName: courseType ? courseType.name : '',
          courseMessage,
          externalCourseId: externalCourseId ? externalCourseId : '',
          courseStates,
        };
        console.log(defaultValues);
        setDefaultValues(defaultValues);
        setIsLoading(false);
      }
    };

    getItem();
  }, [courseId]);

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <MultiStepForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onCloseModal={execOnCloseModal}
        isEdit={true}
      />
    </Grid>
  );
};

export default Edit;
