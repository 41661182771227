import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TopStatesLoading from './TopStatesLoading';
import { ChartValues } from 'features/reporting/shared/types';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import NoDataFound from 'components/NoDataFound';

let topStatesRequestController: AbortController | undefined;

const TopStates = ({
  courseId,
  startDate,
  endDate,
  location,
  name,
  setLoading,
  loading,
}: {
  courseId: string;
  startDate: Date | null;
  endDate: Date | null;
  location?: any;
  name?: string;
  setLoading: React.Dispatch<React.SetStateAction<any>>;
  loading: any;
}) => {
  const { callService } = useCallService();
  const [topStates, setTopStates] = useState<ChartValues[]>([]);

  const getTopState = async () => {
    if (topStatesRequestController) topStatesRequestController.abort();
    try {
      if (courseId) {
        topStatesRequestController = new AbortController();
        const { response } = await callService({
          resource: httpRoutes.reporting.course.getCreatorCourseTopStates({
            courseId,
            startDate,
            endDate,
            location,
            name,
            order: 'desc',
            controller: topStatesRequestController,
          }),
        });

        if (response) {
          setTopStates(
            response.map((r: any) => ({
              label: r.state,
                value: r.totalCount,
            }))
          );
          topStatesRequestController = undefined;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState: any) => ({ ...prevState, states: false }));
    }
  };

  useEffect(() => {
    getTopState();
  }, [courseId, loading.states === true]);
  return (
    <Box
      sx={{
        p: 4,
        borderRadius: '15px',
        backgroundColor: 'white',
        flex: '0.34 0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          TOP STATES
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          by course completion
        </Box>
      </Box>
      {loading.states && <TopStatesLoading />}
      {!loading.states && topStates.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {topStates.map((state, index) => {
            if (Number(state.value) > 0)
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom:
                      index !== topStates.length - 1
                        ? '1px solid #BCC6CF'
                        : 'none',
                    pb: 1,
                  }}
                  key={state.label}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '16px',
                      lineHeight: '32px',
                      color: '#162738',
                    }}
                  >
                    {state.label}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: '300',
                      fontSize: '40px',
                      lineHeight: '120%',
                      color: '#287E7D',
                    }}
                  >
                    {state.value}
                  </Box>
                </Box>
              );
          })}
        </Box>
      )}
      {!loading.states && topStates.length === 0 && <NoDataFound />}
    </Box>
  );
};

export default TopStates;
