import { useState, ReactNode } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import SeedTalentLogo from 'assets/images/icons/seed-talent.svg';

import LeftNavigationDrawer from 'layout/MainLayout/LeftNavigationDrawer';
import { useTheme } from '@mui/material/styles';
import { MenuIcon } from 'material-icons';
import useWindowSize from 'hooks/useWindowSize';

const MainLayout = ({ children = null }: { children?: ReactNode }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const matchUpMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedOption, setSelectedOption] = useState('/');

  const [isDrawerOpen, setIsDrawerOpen] = useState(matchUpMD);

  const onDrawerToggle = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: windowSize.height,
        width: windowSize.width,
        overflow: 'hidden',
        backgroundColor: '#F3F7FD',
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          background:
            'linear-gradient(151.36deg, #054662 15.62%, #67B398 141.87%)',
        }}
      >
        <Toolbar>
          <Box sx={{ display: 'flex' }}>
            {matchDownMD && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                sx={{ pl: '5px' }}
                edge="start"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => navigate('/')}
          >
            <img height="15px" src={SeedTalentLogo} alt="Seed Talent" />
          </Box>
        </Toolbar>
      </AppBar>

      <LeftNavigationDrawer
        isDrawerOpen={isDrawerOpen}
        handleDrawerToggle={onDrawerToggle}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />

      <Container
        id="main-container"
        component="main"
        maxWidth="xl"
        sx={{
          display: 'flex',
          flex: '1',
          marginTop: '50px',
          overflow: 'hidden',
        }}
      >
        <Outlet />
        {children}
      </Container>
    </Box>
  );
};

export default MainLayout;
