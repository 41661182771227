import { FilterType } from '../filterTypes';

import SelectFilter from './SelectFilter';
import SwitchFilter from './SwitchFilter';
import TextFilter from './TextFilter';
import DateFilter from './DateFilter';
import AsyncSelectFilter from './AsyncSelectFilter';
import BlurTextFilter from './BlurTextFilter';
import LocationFilter from './LocationFilter/LocationFilter';

const Filter = ({
  filter,
  filterValue,
  setFilterValue,
}: {
  filter: FilterType;
  filterValue: any;
  setFilterValue: (id: string, value: any) => void;
}) => {
  const handleChange = (value: any) => {
    setFilterValue(filter.id, value);
  };
  return (
    <>
      {filter.type === 'select' && (
        <SelectFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
          value={filterValue}
          options={filter.options || []}
        />
      )}
      {filter.type === 'text' && (
        <TextFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
          value={filterValue}
          infoMsg={filter.props?.infoMsg}
          onClearValue={filter.props?.onClearValue}
        />
      )}
      {filter.type === 'blur_text' && (
        <BlurTextFilter
          id={filter.id}
          label={filter.label}
          onBlur={handleChange}
          value={filterValue}
          infoMsg={filter.props?.infoMsg}
        />
      )}
      {filter.type === 'switch' && (
        <SwitchFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
          value={filterValue}
        />
      )}
      {filter.type === 'date' && (
        <DateFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
          value={filterValue}
          minDate={filter.props?.minDate}
          maxDate={filter.props?.maxDate}
          customMinDate={filter.props?.customMinDate}
          customMaxDate={filter.props?.customMaxDate}
          infoMsg={filter.props?.infoMsg}
        />
      )}
      {filter.type === 'async' && (
        <AsyncSelectFilter
          id={filter.id}
          label={filter.label}
          getOptions={filter.getOptions}
          onChange={handleChange}
        />
      )}
      {filter.type === 'location' && (
        <LocationFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default Filter;
