import React, { useEffect, useState } from 'react';
import { useCallService } from '../../../../hooks';
import { ISelectOptionProps } from '../../../formFields/MultipleSelectCheckmarksFormField';
import httpRoutes from '../../../../utils/httpRoutes';
import { SelectFilter } from '../index';

interface StateSelectorProps {
  countryId?: number;
  onChange: any;
}

export const StateFilter = (props: StateSelectorProps) => {
  const initialStates = { value: '0', label: 'All states'};
  const { callService } = useCallService();
  const [states, setStates] = useState<ISelectOptionProps[]>([initialStates]);
  const [selectedStateId, setSelectedStateId] = useState<number>(0);

  const getStates = async () => {
    if (props.countryId) {
      const { response } = await callService({
        resource: httpRoutes.location.getStatesByCountryId(props.countryId),
      });

      if (response) {
        const statesResponse = response.map((item: any) => {
          return {
            value: item.id,
            label: `${item.name} (${item.code})`,
          };
        });
        statesResponse.unshift(initialStates);
        setStates(statesResponse);
      }
    }
  };

  useEffect(() => {
    if (props.countryId) {
      getStates();
    } else {
      setStates([initialStates]);
    }
  }, [props.countryId]);

  const handleChange = (selectedValue: number) => {
    setSelectedStateId(selectedValue);
    props.onChange({ stateId: selectedValue != 0 ? selectedValue : undefined });
  };

  return (
    <SelectFilter
      id={'stateId'}
      options={states}
      label={'State'}
      value={selectedStateId}
      onChange={handleChange}
    ></SelectFilter>
  );
};
