import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import TopCommunitiesLoading from './TopCommunitiesLoading';
import { useTheme } from '@mui/material/styles';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import BarCharts from '../../../../shared/BarCharts';
import { ChartValues } from '../../../../shared/types';
import NoDataFound from 'components/NoDataFound';

const ConditionalBarCharts = ({
  topCommunities,
}: {
  topCommunities: ChartValues[];
}) => {
  const allValuesAreZero = topCommunities.every(
    (item: ChartValues) => item.value === 0
  );

  if (topCommunities.length === 0 || allValuesAreZero) {
    return <NoDataFound />;
  }

  return <BarCharts chartValues={topCommunities} />;
};

let topCommunitiesRequestController: AbortController | undefined;

const TopCommunities = ({
  courseId,
  startDate,
  endDate,
  location,
  name,
  setLoading,
  loading,
}: {
  courseId: string;
  startDate: Date | null;
  endDate: Date | null;
  location?: any;
  name?: string;
  setLoading: React.Dispatch<React.SetStateAction<any>>;
  loading: any;
}) => {
  const { callService } = useCallService();

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [topCommunities, setTopCommunities] = useState<ChartValues[]>([]);

  const getTopCommunities = async () => {
    if (topCommunitiesRequestController)
      topCommunitiesRequestController.abort();
    try {
      if (courseId) {
        topCommunitiesRequestController = new AbortController();
        const { response } = await callService({
          resource: httpRoutes.reporting.course.getCreatorCourseTopCommunities({
            courseId,
            startDate,
            endDate,
            location,
            name,
            order: 'desc',
            controller: topCommunitiesRequestController,
          }),
        });

        if (response) {
          setTopCommunities(
            response.map((r: any) => ({
              label: r.groupName,
              value: r.totalCompleted,
            }))
          );
          topCommunitiesRequestController = undefined;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState: any) => ({ ...prevState, topCommunities: false }));
    }
  };

  useEffect(() => {
    getTopCommunities();
  }, [courseId, loading.topCommunities === true]);

  return (
    <Box
      sx={{
        p: 4,
        borderRadius: '15px',
        flexDirection: 'column',
        width: matchDownLG ? '100%' : '588px',
        backgroundColor: 'white',
        flex: '0.63 0 auto',
        display: 'flex',
        gap: 1,
        overflow: 'auto',
      }}
    >
      <Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          TOP COMMUNITIES
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          by course completion
        </Box>
      </Box>
      {loading.topCommunities ? (
        <TopCommunitiesLoading />
      ) : (
        <ConditionalBarCharts topCommunities={topCommunities} />
      )}
    </Box>
  );
};

export default TopCommunities;
