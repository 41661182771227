import { useEffect, useState } from 'react';

import { CheckCircleOutline } from '@mui/icons-material';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { DenyIcon } from 'material-icons';

import { useCallService } from 'hooks';

import { getDateDifference } from 'utils/date';
import httpRoutes from 'utils/httpRoutes';

import { usePendingMembersDispatcher } from 'providers/PendingMembersProvider/hooks/usePendingMembersDispatcher';

const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Member Name',
  },
  {
    id: 'groupName',
    type: 'text',
    label: 'Community Name',
  },
  {
    id: 'location',
    type: 'location',
    label: 'Location'
  },
];

type UserActionHandler = (groupId: string, userId: string) => void;

const columns = (
  approveUser: UserActionHandler,
  denyUser: UserActionHandler,
  isProcessing: boolean
): DataGridColumnProps[] => [
  {
    headerName: 'Name',
    field: 'name',
    sortable: true,
    filterable: false,
    flex: 0.3,
    ColumnComponent: (params: any) => {
      return (
        <>
          {params.row.user.firstName} {params.row.user.lastName}
        </>
      );
    },
  },
  {
    headerName: 'Email',
    field: 'email',
    sortable: true,
    filterable: false,
    flex: 0.3,
    ColumnComponent: (params: any) => {
      return <>{params.row.user.email}</>;
    },
  },
  {
    headerName: 'Community',
    field: 'groupName',
    sortable: true,
    filterable: false,
    flex: 0.3,
    ColumnComponent: (params: any) => {
      return <>{params.row.group.name}</>;
    },
  },
  {
    headerName: 'Days Since Request',
    field: 'created',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.3,
    type: 'number',
    ColumnComponent: (params: any) => {
      const _daysSince = getDateDifference(new Date(params.row.created));

      return <div style={{ textAlign: 'left' }}>{_daysSince}</div>;
    },
  },
  {
    headerName: 'Action',
    headerAlign: 'center',
    align: 'center',
    field: 'action',
    filterable: false,
    sortable: false,
    flex: 0.25,
    ColumnComponent: (params: any) => {
      const _groupId = params.row.group.id;
      const _userId = params.row.user.id;

      return (
        <>
          <IconButton
            disabled={isProcessing}
            onClick={() => approveUser(_groupId, _userId)}
          >
            <CheckCircleOutline color={isProcessing ? 'disabled' : 'success'} />
          </IconButton>
          <IconButton
            disabled={isProcessing}
            onClick={() => denyUser(_groupId, _userId)}
          >
            <DenyIcon color={isProcessing ? 'disabled' : 'error'} />
          </IconButton>
        </>
      );
    },
  },
];

let pendingUsersRequestController: AbortController | undefined;

const PendingMembersGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { checkNotifications } = usePendingMembersDispatcher();

  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const getPendingUsers = async () => {
    setLoading(true);
    if (pendingUsersRequestController) {
      pendingUsersRequestController.abort();
    }
    try {
      checkNotifications();
      pendingUsersRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.groups.getPendingMembersByFacilitator({
          controller: pendingUsersRequestController,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        pendingUsersRequestController = undefined;
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const approveUser = async (groupId: string, userId: string) => {
    try {
      setIsProcessing(true);
      const { response } = await callService({
        resource: httpRoutes.groups.approveMemberRequest(groupId, userId),
        successMessage: 'Member added to community successfully!',
      });

      if (response) {
        getPendingUsers();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsProcessing(false);
    }
  };

  const denyUser = async (groupId: string, userId: string) => {
    try {
      setIsProcessing(true);
      const { response } = await callService({
        resource: httpRoutes.groups.denyMemberRequest(groupId, userId),
        successMessage: 'Member denied access to community!',
      });

      if (response === '') {
        getPendingUsers();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    getPendingUsers();
  }, [filters, state]);

  return (
    <DataGrid
      rows={gridData.rows}
      columns={columns(approveUser, denyUser, isProcessing)}
      filters={filterModel}
      handleFilterChange={setFilters}
      totalRows={gridData.totalRows}
      loading={loading}
    />
  );
};

const PendingMembers = () => (
  <ControlledDataGrid>
    <PendingMembersGrid />
  </ControlledDataGrid>
);

export default PendingMembers;
