import { useState, useEffect } from 'react';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import logo from 'assets/logos/logo.png';

import {
  AddIcon,
  CourseAdminIcon,
  EditIcon,
  PreviewIcon,
} from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Create from '../dialogs/Create';
import Edit from '../dialogs/Edit';

import httpRoutes from 'utils/httpRoutes';
import { REACT_APP_CLOUDFRONT_URL } from 'config/config';
import Alert from 'components/Alert';
import { useAuthState } from '../../../providers/AuthProvider/hooks/useAuthState';
import Preview from 'components/courseBuilder/Preview';
import { Checkbox } from '@mui/material';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Course Name',
  },
];

const OganizationCoursesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();
  const [scormAlert, setScormAlert] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const {
    authState: {
      user: { roles },
      isAdmin,
      organization: { id: organizationId },
    },
  } = useAuthState();
  const isCourseBuilder = roles.includes('Course Builder');

  const onCloseModal = async (scormUpdated?: boolean) => {
    hideDialog();
    await getCourses();

    if (scormUpdated) {
      setScormAlert(true);
    }
  };

  const archiveCourse = async (
    value: boolean,
    courseId: string,
    name: string
  ) => {
    const { response } = await callService({
      resource: httpRoutes.courseManagement.archive(courseId, value),
      successMessage: `${name} has been updated successfully!`,
    });

    if (response) {
      await getCourses();
    }
  };

  const showCreateDialog = () => {
    showDialog({
      content: <Create onCloseModal={onCloseModal} />,
      fullScreen: true,
    });
  };

  const showEditDialog = (courseId: string) => {
    showDialog({
      content: <Edit onCloseModal={onCloseModal} courseId={courseId} />,
      fullScreen: true,
    });
  };

  const showPreviewDialog = (courseId: string) => {
    showDialog({
      content: <Preview courseId={courseId} hideDialog={hideDialog} />,
      fullScreen: true,
    });
  };

  useEffect(() => {
    getCourses();
  }, [filters, state]);

  const getCourses = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.courseManagement.getMany({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Header Image',
      field: 'imageUrl',
      sortable: false,
      filterable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        if (params.row.imageUrl) {
          return (
            <img
              src={`${REACT_APP_CLOUDFRONT_URL}/${params.row.imageUrl}`}
              height="40"
            />
          );
        }
        return <img src={logo} alt="Seed Talent Logo" height="40" />;
      },
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.5,
    },
    {
      headerName: 'Reference',
      field: 'reference',
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Active',
      field: 'active',
      sortable: true,
      filterable: false,
      hide: true,
      type: 'boolean',
      flex: 0.1,
    },
    {
      headerName: 'Archived',
      field: 'archived',
      sortable: false,
      filterable: false,
      hide: true,
      type: 'boolean',
      flex: 0.1,
      ColumnComponent: (params: any) => {
        return (
          <Checkbox
            checked={params.row.archived}
            onClick={(e: any) => {
              archiveCourse(e.target.checked, params.row.id, params.row.name);
            }}
          />
        );
      },
    },
  ];

  if (isAdmin || isCourseBuilder) {
    columns.push({
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.15,
      align: 'center',
      headerAlign: 'center',
      ColumnComponent: (params: any) => {
        return (
          <>
            {params.row.courseSourceId === 3 && (
              <IconButton
                onClick={() => showPreviewDialog(params.row.externalCourseId)}
              >
                <PreviewIcon />
              </IconButton>
            )}
            <IconButton onClick={() => showEditDialog(params.row.id)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
    });
  }

  return (
    <PageContainer
      title="Course Admin"
      icon={<CourseAdminIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        (isAdmin || isCourseBuilder) && (
          <Button
            label="Create Course"
            onClick={showCreateDialog}
            startIcon={<AddIcon />}
            disabled={organizationId === ''}
          />
        )
      }
    >
      {scormAlert && (
        <Alert
          severity="info"
          text="It may take a few moments for the SCORM to be fully processed and validated."
        />
      )}
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const OganizationCourses = () => (
  <ControlledDataGrid>
    <OganizationCoursesGrid />
  </ControlledDataGrid>
);

export default OganizationCourses;
