/* eslint-disable */
import {
  memo,
  useCallback,
  useMemo,
  useState,
  ReactNode,
  useEffect,
} from 'react';

import Cookies from 'js-cookie';
import jwt from 'jwt-decode';

import { useNavigate, useParams } from 'react-router-dom';

import AuthContext, { AUTH_INITIAL_STATE, User } from './contexts/AuthContext';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import { getToken } from '../../utils/authentication';

type DecodedTokenProps = {
  exp: number;
  authenticated: boolean;
  userId: string;
  permissions: string[];
  isAlias: boolean;
  adminId?: string;
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState(AUTH_INITIAL_STATE);

  const { callService } = useCallService();
  const router = useNavigate();
  const params = useParams();
  const navigate = useNavigate();

  const loadUserFromCookies = async () => {
    const token = getToken();

    if (token) {
      const currentDate = Number(new Date().getTime().toString().slice(0, 10));

      const decodedToken: DecodedTokenProps = jwt(token);

      if (currentDate < decodedToken.exp) {
        const { response } = await callService({
          resource: httpRoutes.users.getUserProfile(),
        });

        if (response) {
          const roles = response.userRoles.map(
            (ur: { role: { name: string } }) => ur.role.name
          );

          setAuthState({
            authenticated: decodedToken.authenticated,
            user: {
              id: response.id,
              firstName: response.firstName,
              lastName: response.lastName,
              email: response.email,
              state: response.state,
              lastLogin: response.lastLogin,
              roles,
              facilitatorGroupsIds: response.facilitatorGroupsIds,
              managerGroupIds: response.managerGroupIds,
              enrolledInDdl: response.enrolledInDdl,
              ddl: response.ddl,
              managerOrganizationId: response.managerOrganizationId,
            },
            token,
            tokenExpiration: decodedToken.exp,
            permissions: decodedToken.permissions,
            isAlias: decodedToken.isAlias,
            adminId: decodedToken.adminId,
            organization: response.organization,
            isAdmin: roles.includes('Admin') || roles.includes('Super Admin'),
          });
        }
      }

      if (currentDate > decodedToken.exp) {
        logout();
      }
    }
  };

  useEffect(() => {
    loadUserFromCookies();
  }, []);

  const login = useCallback(
    ({
      user,
      token,
      isAlias,
      adminId,
      facilitatorGroupsIds,
      managerGroupIds,
      enrolledInDdl,
      ddl,
      organization,
      isAdmin,
    }: {
      user: any;
      token: string;
      isAlias: boolean;
      adminId?: string;
      facilitatorGroupsIds: string[];
      managerGroupIds: string[];
      enrolledInDdl: boolean;
      ddl: {
        basketSize: boolean;
        houseBrands: boolean;
        hasDutchieIntegration: boolean;
      };
      organization: { id: string; name: string };
      isAdmin: boolean;
    }) => {
      const decodedToken: DecodedTokenProps = jwt(token);

      if (!user.userRoles) {
        throw new Error('User does not have roles');
      }
      const roles = user.userRoles.map(
        (ur: { role: { name: string } }) => ur.role.name
      );
      setAuthState({
        authenticated: true,
        user: {
          ...user,
          facilitatorGroupsIds,
          managerGroupIds,
          enrolledInDdl,
          ddl,
          roles,
        },
        token,
        tokenExpiration: decodedToken.exp,
        permissions: decodedToken.permissions,
        isAlias,
        adminId,
        organization,
        isAdmin: roles.includes('Admin') || roles.includes('Super Admin'),
      });

      if (isAlias) {
        // remove previous user token before setting new
        Cookies.remove('st-token');
        Cookies.set('st-token', token);
        // take them to the home page after signing in as another user
        router('/');
      } else {
        Cookies.set('st-token', token);
      }
    },
    [router]
  );

  const logout = useCallback(async () => {
    Cookies.remove('st-token');
    navigate('/login');
    setAuthState({ ...AUTH_INITIAL_STATE, authenticated: false });
  }, []);

  const updateUser = useCallback(
    ({ user }: { user: User }) => {
      setAuthState((prevState) => ({
        ...prevState,
        user,
      }));
    },
    [authState]
  );

  const getPermissions = useCallback(() => {
    return authState.permissions;
  }, [authState]);

  const value = useMemo(
    () => ({
      authState,
      login,
      logout,
      updateUser,
      getPermissions,
    }),
    [authState, login, logout, updateUser, getPermissions]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default memo(AuthProvider);
