import React from 'react';
import { Avatar, Box, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

import { CourseCarouselProps } from './types';

import DisplayText from 'components/DisplayText';
import CourseCard from './CourseCard';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1500 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1499, min: 1000 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 1170, min: 822 },
    items: 2,
    slidesToSlide: 2,
  },
  tinyMobile: {
    breakpoint: { max: 822, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CustomButtonGroupAsArrows = (params: any) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const {
    title,
    titleComponent,
    carouselState: { currentSlide, slidesToShow, totalItems },
  } = params;

  const showPagination = totalItems > slidesToShow;

  const totalPages = totalItems;

  const currentSlideCounter = currentSlide + 1;

  const totalPagesCounter = totalPages;

  const hasNext = totalItems !== currentSlide + slidesToShow;
  const hasPrevious = currentSlide !== 0;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 1,
        mb: 1,
        alignItems: 'center',
      }}
    >
      <Box>
        {titleComponent ? (
          titleComponent
        ) : (
          <DisplayText type="sh2" text={title} variant="medium" />
        )}
      </Box>
      {showPagination && (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Box sx={{ cursor: 'pointer' }} onClick={params.previous}>
            <IconButton disabled={!hasPrevious}>
              <Avatar
                sx={{
                  background: hasPrevious
                    ? 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)'
                    : 'white',
                  height: 30,
                  width: 30,
                }}
              >
                <KeyboardArrowLeftRoundedIcon
                  sx={{
                    color: hasPrevious ? 'white' : '#AA5BB9',
                  }}
                  fontSize="medium"
                />
              </Avatar>
            </IconButton>
          </Box>
          <Box sx={{ display: matchDownMD ? 'none' : 'block' }}>
            <DisplayText
              type="sh2"
              text={`${currentSlideCounter} / ${totalPagesCounter}`}
              variant="medium"
            />
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={params.next}>
            <IconButton disabled={!hasNext}>
              <Avatar
                sx={{
                  background: hasNext
                    ? 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)'
                    : 'white',
                  height: 30,
                  width: 30,
                }}
              >
                <KeyboardArrowRightRoundedIcon
                  fontSize="medium"
                  sx={{
                    color: hasNext ? 'white' : '#AA5BB9',
                  }}
                />
              </Avatar>
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const CourseCarousel = ({
  title,
  courses,
  titleComponent,
  style,
}: CourseCarouselProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column-reverse',
        ...style?.container,
      }}
    >
      <Carousel
        responsive={responsive}
        renderButtonGroupOutside={true}
        arrows={false}
        customButtonGroup={
          <CustomButtonGroupAsArrows
            titleComponent={titleComponent}
            title={title}
          />
        }
      >
        {courses.map((course: any) => (
          <Box key={course.id} sx={{ padding: '5px', marginRight: '10px' }}>
            <CourseCard key={course.id} course={course} />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default CourseCarousel;
