import { useSnackbar } from 'notistack';

interface CallServiceProps {
  resource: any;
  throwOnError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  showSnackbar?: boolean;
  autoHideDuration?: number;
}

const useCallService = () => {
  const { enqueueSnackbar } = useSnackbar();

  const callService = async ({
    resource,
    throwOnError = false,
    errorMessage, // optional and overrides
    successMessage, // optional and overrides,
    showSnackbar = false,
    autoHideDuration = 2000,
  }: CallServiceProps) => {
    try {
      const response = await resource;

      if (successMessage) {
        enqueueSnackbar(successMessage, {
          variant: 'success',
          autoHideDuration,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }

      return {
        response: response.data,
        error: null,
      };
    } catch (error: any) {
      if (error?.code === 'ERR_CANCELED') {
        return {
          response: null,
          error,
        };
      }

      let messages = null;

      if (errorMessage) {
        messages = errorMessage;
      }

      if (!errorMessage && error) {
        if (error.response && error.response.data) {
          messages = getResponseErrors(error);
        }
      }

      if (!throwOnError) {
        if (typeof messages === 'string') {
          enqueueSnackbar(messages, {
            variant: 'error',
            autoHideDuration,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        } else if (Array.isArray(messages)) {
          console.log('array', messages);
          messages.forEach((_error: any) => {
            if (_error.errors) {
              _error.errors.forEach((_errorMessage: string) =>
                enqueueSnackbar(_errorMessage, {
                  variant: 'error',
                  autoHideDuration,
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                  },
                })
              );
            }
          });
        }

        if (error.status === 401) {
          console.log('redirect');
        }

        return {
          response: null,
          error,
        };
      }

      if (showSnackbar) {
        if (typeof messages === 'string') {
          enqueueSnackbar(messages, {
            variant: 'error',
            autoHideDuration,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        } else {
          if (typeof messages === 'string') {
            enqueueSnackbar(messages, {
              variant: 'error',
              autoHideDuration,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            });
          } else {
            messages.forEach((_error: any) => {
              if (_error.errors) {
                _error.errors.forEach((_errorMessage: string) =>
                  enqueueSnackbar(_errorMessage, {
                    variant: 'error',
                    autoHideDuration,
                    anchorOrigin: {
                      horizontal: 'right',
                      vertical: 'top',
                    },
                  })
                );
              }
            });
          }
        }
      }
      throw messages;
    }
  };

  return { callService };
};

export const getResponseErrors = (error: any) => {
  if (error && error.response && error.response.data.message) {
    return error.response.data.message;
  } else if (error && error.response && error.response.data) {
    return error.response.data;
  } else {
    return ['An Unexpected error has occurred. Please try again later.'];
  }
};

export default useCallService;
