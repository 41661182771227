import { CountrySelector } from './CountrySelector';
import { StateSelector } from './StateSelector';
import React, { useState } from 'react';
import { Grid } from '@mui/material';

interface LocationSelectorProps {
  control: any;
  errors?: any;
  selectedCountryId?: number | number[];
  multiState?: boolean;
  multiCountry?: boolean;
  countryFieldName?: string;
  stateFieldName?: string;
  sx?: any;
}

export const LocationSelector = (props: LocationSelectorProps) => {
  const [selectedCountryId, setSelectedCountryId] = useState(
    props.selectedCountryId
  );

  const handleCountryChange = (countryId: number) => {
    setSelectedCountryId(countryId);
  };

  return (
    <Grid container columnSpacing={1} flexDirection="row">
      <Grid item xs={12} sm={6}>
        <CountrySelector
          control={props.control}
          onCountryChange={handleCountryChange}
          errors={props.errors}
          name={props.countryFieldName}
          multiSelect={props.multiCountry}
          sx={props.sx}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StateSelector
          control={props.control}
          countryId={selectedCountryId}
          multiSelect={props.multiState}
          errors={props.errors}
          name={props.stateFieldName}
          sx={props.sx}
        />
      </Grid>
    </Grid>
  );
};
