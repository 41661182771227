import * as React from 'react';
import { useState } from 'react';
import { FilterProps } from '../../filterTypes';
import { CountryFilter } from './CountryFilter';
import { StateFilter } from './StateFilter';
import { Grid } from '@mui/material';

export default function LocationFilter({ onChange }: FilterProps) {
  const [selectedCountryId, setSelectedCountryId] = useState(0);

  const handleCountryChange = (countryId: number) => {
    setSelectedCountryId(countryId);
  };

  return (
    <Grid container columnSpacing={1} alignItems="center" sx={{ width: '350px'}}>
      <Grid item xs={12} sm={6}>
        <CountryFilter
          onChange={onChange}
          onCountryChange={handleCountryChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StateFilter onChange={onChange} countryId={selectedCountryId} />
      </Grid>
    </Grid>
  );
}
