import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from 'components/buttons/Button';

import PageContainer from 'components/PageContainer';

import { useCallService } from 'hooks';

import { EditIcon, OrganizationIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Edit from '../dialogs/Edit';

import Bundles from '../partials/Bundles';
import Communities from '../partials/Communities';
import Members from '../partials/Members';

import httpRoutes from 'utils/httpRoutes';

import {
  OrganizationFormInput,
  ORGANIZATION_FORM_INITIAL_STATE,
} from '../forms/OrganizationForm';
import AssignmentList from '../../course-assignment/AssignmentList';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`organization-tabpanel-${index}`}
      aria-labelledby={`organization-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `organization-tab-${index}`,
    'aria-controls': `organization-tabpanel-${index}`,
  };
}

const Organization = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  const params = useParams();

  const organizationId = params && params.id ? params?.id.toString() : '';

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [organization, setOrganization] = useState<OrganizationFormInput>(
    ORGANIZATION_FORM_INITIAL_STATE
  );

  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();

  const showEditDialog = () => {
    showDialog({
      content: (
        <Edit onSuccess={getOrganization} defaultValues={organization} />
      ),
      size: 'sm',
    });
  };

  const getOrganization = async () => {
    setLoading(true);
    try {
      if (params.id) {
        const { response } = await callService({
          resource: httpRoutes.organizations.getOne(params?.id),
        });

        if (response) {
          setOrganization(response);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrganization();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PageContainer
      title={organization.name}
      icon={<OrganizationIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Button
          label="Edit Organization"
          onClick={showEditDialog}
          startIcon={<EditIcon />}
        />
      }
    >
      <Grid container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="organization tab"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Communities" {...a11yProps(0)} />
              <Tab label="Members" {...a11yProps(1)} />
              <Tab label="Bundles" {...a11yProps(2)} />
              <Tab label="Assignments" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Communities organizationId={organizationId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Members organizationId={organizationId} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Bundles organizationId={organizationId} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            {!loading && <AssignmentList organizationId={organizationId} />}
          </TabPanel>
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default Organization;
