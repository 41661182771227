import axios from 'axios';
import httpAdapter from 'axios/lib/adapters/http';
import { useNavigate } from 'react-router-dom';
import { getToken } from './authentication';

import {
  REACT_APP_API_URL,
  REACT_APP_POS_IMPORTER_API_URL,
} from 'config/config';
import { getBusinessUnit } from './businessUnit';

export const createAxiosInstance = (baseUrl, contentType) => {
  const instance = axios.create({
    baseURL: baseUrl,
    adapter: httpAdapter,
    headers: {
      'Content-Type': contentType,
      'business-unit': getBusinessUnit(),
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const token = getToken();

      config.headers.authorization = `Bearer ${token}`;

      return config;
    },
    (error) => {
      console.error('Request error: ', error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        const navigate = useNavigate();
        navigate('/login');
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const instance = createAxiosInstance(
  // eslint-disable-next-line no-undef
  REACT_APP_API_URL,
  'application/json'
);

export const multiPartInstance = createAxiosInstance(
  // eslint-disable-next-line no-undef
  REACT_APP_API_URL,
  'multipart/form-data'
);

export const posImporterInstance = createAxiosInstance(
  REACT_APP_POS_IMPORTER_API_URL,
  'application/json'
);

export default instance;
