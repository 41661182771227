import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldError, useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { DeleteIcon, SaveIcon } from 'material-icons';

import {
  EmailFormField,
  MultiSelectFormField,
  TextFormField,
} from 'components/formFields';
import { SelectOptionProps } from 'components/formFields/types';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { LoadingButton } from '@mui/lab';

import { deleteAttribute } from 'utils/common';
import httpRoutes from 'utils/httpRoutes';

import { useCallService, useDebouncedEffect } from 'hooks';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from 'components/buttons/IconButton';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useAuthState } from '../../../providers/AuthProvider/hooks/useAuthState';

export type PublicUserFormInput = {
  id?: string;
  lastName?: string;
  firstName?: string;
  email: string;
  userRoles?: any[];
  newUserRoles?: string[];
  userGoups?: ICommunity[];
  newUserGroups?: ICommunity[];
  status?: string;
  archived?: number;
  managerOrganizationId?: string;
};

export const INITIAL_FORM_STATE: PublicUserFormInput = {
  id: '',
  lastName: '',
  firstName: '',
  email: '',
  newUserGroups: [],
  status: 'Active',
  archived: 0,
};

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} mt={3}>
      <Typography variant="h3">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

interface ICommunity {
  groupId: string;
  name: string;
  isPrimary: number;
  notify: number;
  facilitator: number;
  manager: number;
  ddl: number;
}

const PublicUserForm = ({
  defaultValues,
  onSubmit,
  isSubmitting,
}: {
  defaultValues?: PublicUserFormInput;
  onSubmit: (values: PublicUserFormInput) => void;
  isSubmitting: boolean;
}) => {
  const {
    authState: { user },
  } = useAuthState();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [roles, setRoles] = useState<SelectOptionProps[]>([]);
  const [userGroups, setUserGroups] = useState<ICommunity[]>(
    defaultValues && defaultValues.newUserGroups
      ? defaultValues.newUserGroups
      : []
  );
  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  // set default roles on edit
  const userRoleDefaultValues = defaultValues?.userRoles
    ? defaultValues.userRoles.map((role) => role.roleId)
    : [];

  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();

  const create = (values: PublicUserFormInput) => {
    Object.keys(values).forEach((key) => {
      if (values[key as keyof PublicUserFormInput] === '') {
        delete values[key as keyof PublicUserFormInput];
      }
    });
    values.newUserGroups = userGroups;
  };

  const update = (values: PublicUserFormInput) => {
    const userRoles = values?.userRoles?.map((x) => x.roleId);
    const roleArraysAreEqual = _.isEqual(userRoles, values?.newUserRoles);
    if (roleArraysAreEqual) {
      deleteAttribute(values, 'newUserRoles');
    }

    const userGroupsAreEqual = _.isEqual(userGroups, values.userGoups);
    if (!userGroupsAreEqual) {
      values.newUserGroups = userGroups;
    }
  };

  const preSubmitUpload = async (values: PublicUserFormInput) => {
    const isUpdate = values.id !== undefined;

    if (!isUpdate) {
      create(values);
    } else {
      update(values);
    }

    deleteAttribute(values, 'userGoups');
    deleteAttribute(values, 'userRoles');

    onSubmit({ ...values });
  };

  const addCommunity = (community: ICommunity) => {
    const updatedUserGroups = [...userGroups];

    updatedUserGroups.push(community);
    setUserGroups(updatedUserGroups);
  };

  const onIsPrimaryClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    updatedUserGroups.forEach((x) => (x.isPrimary = 0));

    if (communityToUpdate) {
      communityToUpdate.isPrimary = communityToUpdate.isPrimary === 1 ? 0 : 1;
    }

    setUserGroups(updatedUserGroups);
  };

  const onManagerClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    if (communityToUpdate) {
      communityToUpdate.manager = communityToUpdate.manager === 1 ? 0 : 1;
      communityToUpdate.facilitator = 0;
      communityToUpdate.notify = 0;
    }

    setUserGroups(updatedUserGroups);
  };

  const onFacilitatorClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    if (communityToUpdate) {
      communityToUpdate.facilitator =
        communityToUpdate.facilitator === 1 ? 0 : 1;
    }

    setUserGroups(updatedUserGroups);
  };

  const onDDLClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    if (communityToUpdate) {
      communityToUpdate.ddl = communityToUpdate.ddl === 1 ? 0 : 1;
    }

    setUserGroups(updatedUserGroups);
  };

  const onNotifyClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    if (communityToUpdate) {
      communityToUpdate.notify = communityToUpdate.notify === 1 ? 0 : 1;
    }

    setUserGroups(updatedUserGroups);
  };

  const removeCommunity = (id: string) => {
    const updatedUserGroups = userGroups.filter(
      (community) => community.groupId !== id
    );

    setUserGroups(updatedUserGroups);
  };

  // getUserRoles
  const getRoles = async () => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.getRoles(),
    });

    if (response) {
      setRoles(
        response.map((item: { id: string; name: string }) => {
          return { value: item.id, label: item.name };
        })
      );
    }
  };

  const getGroups = async () => {
    try {
      setOptionsLoading(true);
      const communityListIds = userGroups.map((x) => x.groupId);

      const { response } = await callService({
        resource: httpRoutes.groups.search(inputValue, communityListIds),
      });

      if (response) {
        setOptions(
          response.map((item: { id: string; name: string }) => {
            return { id: item.id, label: item.name };
          })
        );
      }
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  useDebouncedEffect(getGroups, 200, [inputValue]);

  const validationSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    email: Yup.string().required('An email is required'),
    userRoles: Yup.array().nullable(),
    newUserRoles: Yup.array().min(1, 'Please select at least one role'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PublicUserFormInput>({
    mode: 'onBlur',
    defaultValues: {
      id: defaultValues?.id || undefined,
      lastName: defaultValues?.lastName || '',
      firstName: defaultValues?.firstName || '',
      email: defaultValues?.email || '',
      status: 'Active',
      archived: defaultValues?.archived || 0,
      userRoles: defaultValues?.userRoles || [],
      newUserRoles: userRoleDefaultValues,
      userGoups: defaultValues?.newUserGroups || [],
      managerOrganizationId: defaultValues?.managerOrganizationId || '',
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(preSubmitUpload)}>
        <Grid container flexDirection="column">
          <Typography variant="h2" sx={{ pb: 2 }}>
            {defaultValues?.id ? 'Update User Record' : 'Create A New User'}
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="General Info" />
            <Grid item xs={12} md={6}>
              <TextFormField
                name="firstName"
                control={control}
                label="First Name*"
                errors={errors.firstName}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFormField
                name="lastName"
                control={control}
                label="Last Name*"
                errors={errors.lastName}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <EmailFormField
                name="email"
                control={control}
                label="Email*"
                errors={errors.email}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6} mb={2}>
              <Box>
                <TextFormField
                  sx={{ visibility: 'hidden' }}
                  disabled={true}
                  label="Archived"
                  name="archived"
                  control={control}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Security" />
            <Grid item xs={12}>
              <MultiSelectFormField
                name="newUserRoles"
                control={control}
                errors={errors.newUserRoles as FieldError[]}
                label="Roles*"
                options={roles}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Communities" />
            <Grid item xs={12}>
              <Autocomplete
                id="search"
                freeSolo
                fullWidth
                selectOnFocus
                loading={areOptionsLoading}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onChange={(_event, value) => {
                  if (value) {
                    setInputValue('');
                    const newCommunity = {
                      name: value.label,
                      groupId: value.id,
                      isPrimary: userGroups.length === 0 ? 1 : 0,
                      facilitator: 0,
                      manager: 0,
                      ddl: 0,
                      notify: 0,
                    };
                    addCommunity(newCommunity);
                  }
                }}
                options={options}
                getOptionLabel={(option) => option.label || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Find communities"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      endAdornment: (
                        <>
                          {areOptionsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Box
              sx={{ width: '100%', overflow: 'scroll', paddingBottom: '16px' }}
              mt={2}
            >
              {userGroups.length > 0 && (
                <Table size="small">
                  <TableHead
                    sx={{
                      backgroundColor: '#f4f4f4',
                      '& th:nth-of-type(1)': {
                        borderRadius: '1em 0 0 0',
                      },
                      '& th:nth-of-type(7)': {
                        borderRadius: '0 1em 0 0',
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="80%"
                      >
                        Community Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        Primary
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        Manager
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        Facilitator
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        DDL Access
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        Notify
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '& tr:nth-of-type(even)': {
                        backgroundColor: '#f4f4f4',
                      },
                      '& tr:last-of-type': {
                        '& td:nth-of-type(1)': {
                          borderRadius: '0 0 0 1em',
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(2)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(3)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(4)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(5)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(6)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(7)': {
                          borderRadius: '0 0 1em 0',
                          borderBottom: '0px',
                        },
                      },
                      '& tr:first-of-type': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    {userGroups.map((community) => (
                      <TableRow key={community.groupId}>
                        <TableCell
                          sx={{
                            padding: matchDownSM
                              ? '0px 0px 0px 16px'
                              : '0px 16px',
                            fontSize: matchDownSM ? '10px' : '14px',
                          }}
                          size="small"
                          width="80%"
                        >
                          {community.name}
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              checked={community.isPrimary === 1}
                              onChange={() =>
                                onIsPrimaryClick(community.groupId)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              checked={community.manager === 1}
                              onChange={() => onManagerClick(community.groupId)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              disabled={community.manager === 1}
                              checked={community.facilitator === 1}
                              onChange={() =>
                                onFacilitatorClick(community.groupId)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              checked={community.ddl === 1}
                              onChange={() => onDDLClick(community.groupId)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              disabled={community.manager === 1}
                              checked={community.notify === 1}
                              onChange={() => onNotifyClick(community.groupId)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          {((defaultValues?.managerOrganizationId &&
                            user?.managerOrganizationId) ||
                            !defaultValues?.managerOrganizationId) && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                //qwe
                                if (
                                  (defaultValues?.managerOrganizationId &&
                                    user?.managerOrganizationId) ||
                                  !defaultValues?.managerOrganizationId
                                ) {
                                  removeCommunity(community.groupId);
                                }
                              }}
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </div>
  );
};

export default PublicUserForm;
