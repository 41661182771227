import { Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  MultipleSelectCheckmarksFormField,
  SelectFormField,
} from '../formFields';
import httpRoutes from '../../utils/httpRoutes';
import { useCallService } from '../../hooks';
import { ISelectOptionProps } from '../formFields/MultipleSelectCheckmarksFormField';

interface CountrySelectorProps {
  control: any;
  errors?: any;
  onCountryChange: any;
  multiSelect?: boolean;
  name?: string;
  sx?: any;
}

export const CountrySelector = (props: CountrySelectorProps) => {
  const { callService } = useCallService();
  const [countries, setCountries] = useState<ISelectOptionProps[]>([]);

  const getCountries = async () => {
    const { response } = await callService({
      resource: httpRoutes.location.getCountries(),
    });

    if (response) {
      setCountries(
        response.map((item: any) => {
          return {
            value: item.id,
            label: `${item.name}`,
          };
        })
      );
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const handleCountryChange = (e: any) => {
    const selectedCountryId = e.target.value;
    props.onCountryChange(selectedCountryId);
  };

  const multiSelector = (
    <MultipleSelectCheckmarksFormField
      name={props.name || 'countries'}
      limitTags={3}
      options={countries}
      control={props.control}
      label="Select all countries"
      disabled={!countries.length}
      onChange={handleCountryChange}
    />
  );

  const singleSelector = (
    <SelectFormField
      name={props.name || 'countryId'}
      control={props.control}
      label="Country"
      errors={props.errors?.countryId}
      options={countries}
      margin="dense"
      disabled={!countries.length}
      onChange={handleCountryChange}
      selectSx={props.sx}
    />
  );

  if (props.multiSelect) {
    return (
      <Controller
        name={props.name || 'countries'}
        control={props.control}
        render={() => multiSelector}
      />
    );
  }

  return (
    <Controller
      name={props.name || 'countryId'}
      control={props.control}
      render={() => singleSelector}
    />
  );
};
