import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import MultiStepForm from '../forms/MultiStepForm';
import { Grid } from '@mui/material';
import { CourseBuilderFormInput } from 'types/courseTypes/CourseBuilderFormInput';

const Create = ({
  onCloseModal,
}: {
  onCloseModal: (scormAlert?: boolean) => Promise<void>;
}) => {
  const { callService } = useCallService();

  const execOnCloseModal = async (values: CourseBuilderFormInput) => {
    await onCloseModal(Boolean(values.courseFileChanged));
  };

  const onSubmit = async (values: CourseBuilderFormInput) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.courseManagement.create(values),
        successMessage:
          values.source === 'scorm'
            ? 'Course created successfully!'
            : 'Course created successfully! All your changes will be automatically saved.',
        autoHideDuration: 5000,
      });

      if (!response) {
        await execOnCloseModal(values);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <MultiStepForm onSubmit={onSubmit} onCloseModal={execOnCloseModal} />
    </Grid>
  );
};

export default Create;
