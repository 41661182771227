import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { SaveIcon } from 'material-icons';

import {
  SelectFormField,
  NumberFormField,
  CheckboxFormField,
  DateTimeFormField,
} from 'components/formFields';

import { SelectOptionProps } from 'components/formFields/types';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { transformEmptyStringIntoUndefined } from 'utils/common';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import { useSnackbar } from 'notistack';

export type UserTestResultFormInput = {
  id: string;
  courseId: string;
  userId: string;
  created: Date;
  updated: Date;
  progress: number;
  data: string;
  duration: number;
  score: number;
  start: Date;
  end?: Date;
  passed: boolean;
  failCount: number;
  courseRoundId: string;
  externalCourseId?: string;
  courseSourceId?: number;
  locked: boolean;
  lockedTime: Date;
  lockCount: number;
  status: 'To-Do' | 'In-Progress' | 'Completed' | 'Failed';
  assessmentId: string;
  hasReward?: boolean;
  hasCertificate?: boolean;
  certificateCreated?: boolean;
  rewardCreated?: boolean;
  clearData?: boolean;
  clearTracking?: boolean;
  createReward?: boolean;
  createCertificate?: boolean;
  notifyUserCertificate?: boolean;
  notifyUserReward?: boolean;
};

export type CourseRecordFormRequest = {
  userTestResult: {
    id: string;
    courseId: string;
    userId: string;
    created: Date;
    updated: Date;
    progress: number;
    data: string;
    duration: number;
    score: number;
    start: Date;
    end?: Date;
    passed: boolean;
    failCount: number;
    locked: boolean;
    lockedTime: Date;
    lockCount: number;
    status: 'To-Do' | 'In-Progress' | 'Completed' | 'Failed';
    assessmentId: string;
    courseRoundId: string;
  };
  createReward: boolean;
  createCertificate: boolean;
  notifyUserCertificate: boolean;
  notifyUserReward: boolean;
};

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h3">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

const CourseRecordForm = ({
  defaultValues,
  onSubmit,
  courseRoundOptions,
}: {
  defaultValues?: UserTestResultFormInput;
  onSubmit: (values: CourseRecordFormRequest) => void;
  courseRoundOptions: SelectOptionProps[];
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();
  const { enqueueSnackbar } = useSnackbar();

  const preSubmitUpload = async (values: UserTestResultFormInput) => {
    // Object.keys(values).forEach((key) => {
    //   if (values[key as keyof UserTestResultFormInput] === '') {
    //     delete values[key as keyof UserTestResultFormInput];
    //   }
    // });

    if (values.clearData) {
      values.data = '';
      delete values.clearData;
    }

    if (values.clearTracking) {
      const requestData: {
        userId: string;
        externalCourseId: string;
      } = {
        userId: values.userId,
        externalCourseId: values.externalCourseId || '',
      };

      try {
        await callService({
          resource: httpRoutes.courseBuilder.deleteTracking(requestData),
        });
        enqueueSnackbar('Coassemble tracking has been clean successfuly', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      } catch (error: any) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }

      delete values.clearTracking;
      delete values.externalCourseId;
      delete values.courseSourceId;
    }

    const _formattedRequest: CourseRecordFormRequest = {
      userTestResult: {
        id: values.id,
        courseId: values.courseId,
        userId: values.userId,
        created: values.created,
        updated: values.updated,
        progress: values.progress,
        data: values.data,
        duration: values.duration,
        score: values.score,
        start: values.start,
        end: values.end,
        passed: values.passed,
        failCount: values.failCount,
        locked: values.locked,
        lockedTime: values.lockedTime,
        lockCount: values.lockCount,
        status: values.status,
        assessmentId: values.assessmentId,
        courseRoundId: values.courseRoundId,
      },
      createReward: Boolean(values.createReward),
      createCertificate: Boolean(values.createCertificate),
      notifyUserCertificate: Boolean(values.notifyUserCertificate),
      notifyUserReward: Boolean(values.notifyUserReward),
    };

    // console.log(values);
    onSubmit(_formattedRequest);
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    courseId: Yup.string().required('course_id is required'),
    userId: Yup.string().required('user_id is required'),
    created: Yup.date().required('created is required'),
    updated: Yup.date().required('updated is required'),
    progress: Yup.number().nullable(),
    data: Yup.string().nullable(),
    duration: Yup.number().nullable(),
    score: Yup.number().nullable(),
    start: Yup.date().nullable(),
    end: Yup.date().nullable(),
    passed: Yup.boolean().nullable(),
    failCount: Yup.number()
      .integer('Must be an integer')
      .transform(transformEmptyStringIntoUndefined)
      .min(0, 'Must be zero or greater')
      .notRequired(),
    locked: Yup.boolean().nullable(),
    lockedTime: Yup.date().nullable(),
    lockCount: Yup.number()
      .integer('Must be an integer')
      .transform(transformEmptyStringIntoUndefined)
      .min(0, 'Must be zero or greater')
      .notRequired(),
    courseRoundId: Yup.string().nullable(),
    status: Yup.string().nullable(),
    assessmentId: Yup.string().nullable(),
    clearData: Yup.boolean().nullable(),
    createReward: Yup.boolean().nullable(),
    createCertificate: Yup.boolean().nullable(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserTestResultFormInput>({
    mode: 'onBlur',
    defaultValues: {
      id: defaultValues?.id || '',
      courseId: defaultValues?.courseId || '',
      userId: defaultValues?.userId || '',
      created: defaultValues?.created,
      updated: new Date(),
      progress: defaultValues?.progress || 0,
      data: defaultValues?.data || '',
      duration: defaultValues?.duration || 0,
      score: defaultValues?.score || 0,
      start: defaultValues?.start || new Date(),
      end: defaultValues?.end,
      passed: defaultValues?.passed || false,
      failCount: defaultValues?.failCount || 0,
      courseRoundId: defaultValues?.courseRoundId || '',
      locked: defaultValues?.locked || false,
      lockedTime: defaultValues?.lockedTime,
      lockCount: defaultValues?.lockCount || 0,
      status: defaultValues?.status || 'To-Do',
      assessmentId: defaultValues?.assessmentId || '',
      externalCourseId: defaultValues?.externalCourseId || '',
      courseSourceId: defaultValues?.courseSourceId || 0,
      clearData: false,
      createCertificate: false,
      createReward: false,
      clearTracking: false,
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(preSubmitUpload)}>
        <Grid container flexDirection="row" alignContent="center" spacing={1}>
          <Typography variant="h2" sx={{ pb: 2 }}>
            Update User Course Record
          </Typography>

          <FormSectionTitle title="Dates" />

          <Grid container justifyContent="space-between">
            <Grid item>
              <DateTimeFormField
                name="start"
                label="Start Date"
                control={control}
              />
            </Grid>
            <Grid item>
              <DateTimeFormField
                name="end"
                label="End Date"
                control={control}
              />
            </Grid>
          </Grid>

          <FormSectionTitle title="Stats" />

          <Grid item xs={6} md={4}>
            <NumberFormField
              name="score"
              label="Score"
              control={control}
              errors={errors.score}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <NumberFormField
              name="progress"
              label="Progress"
              control={control}
              errors={errors.progress}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <NumberFormField
              name="duration"
              label="Duration"
              control={control}
              errors={errors.duration}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectFormField
              name="status"
              label="Status"
              control={control}
              errors={errors.status}
              options={[
                {
                  label: 'To-Do',
                  value: 'To-Do',
                },
                {
                  label: 'In-Progress',
                  value: 'In-Progress',
                },
                {
                  label: 'Completed',
                  value: 'Completed',
                },
                {
                  label: 'Failed',
                  value: 'Failed',
                },
              ]}
            />
          </Grid>
          <Grid item xs={6} md={4} sx={{ mt: 2.5 }}>
            <CheckboxFormField
              name="passed"
              label="Passed"
              control={control}
              errors={errors.passed}
            />
          </Grid>
          <Grid item xs={6} md={4} sx={{ mt: 2.5 }}>
            <CheckboxFormField
              name="clearData"
              label="Clear UTR Data"
              control={control}
              errors={errors.clearData}
            />
          </Grid>

          {defaultValues?.courseSourceId === 3 && (
            <Grid item xs={6} md={12} sx={{ mt: 2.5 }}>
              <CheckboxFormField
                name="clearTracking"
                label="Clear Coassemble tracking"
                control={control}
                errors={errors.clearTracking}
              />
            </Grid>
          )}

          <FormSectionTitle title="Assessment Data" />

          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item xs={6}>
              <NumberFormField
                name="failCount"
                label="Fail Count"
                control={control}
                errors={errors.failCount}
                disabled={!defaultValues?.assessmentId}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberFormField
                name="lockCount"
                label="Lock Count"
                control={control}
                errors={errors.lockCount}
                disabled={!defaultValues?.assessmentId}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item xs={12} md={6}>
              <SelectFormField
                name="courseRoundId"
                label="# of Round"
                control={control}
                options={courseRoundOptions}
                errors={errors.courseRoundId}
                disabled={!defaultValues?.assessmentId}
              />
            </Grid>

            <Grid item xs={12} md={6} sx={{ mt: matchDownSM ? 0 : 2.5 }}>
              <CheckboxFormField
                name="locked"
                label="Locked"
                control={control}
                errors={errors.locked}
                disabled={!defaultValues?.assessmentId}
              />
            </Grid>
          </Grid>

          <FormSectionTitle title="Certificates" />

          <Grid item xs={6}>
            <CheckboxFormField
              name="createCertificate"
              label="Issue Certificate"
              control={control}
              errors={errors.createCertificate}
              disabled={
                !defaultValues?.hasCertificate ||
                defaultValues.certificateCreated
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxFormField
              name="notifyUserCertificate"
              label="Notify User"
              control={control}
              errors={errors.notifyUserCertificate}
              disabled={
                !defaultValues?.hasCertificate ||
                defaultValues.certificateCreated
              }
            />
          </Grid>

          <FormSectionTitle title="Rewards" />

          <Grid item xs={6}>
            <CheckboxFormField
              name="createReward"
              label="Issue Reward"
              control={control}
              errors={errors.createReward}
              disabled={
                !defaultValues?.hasReward || defaultValues.rewardCreated
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxFormField
              name="notifyUserReward"
              label="Notify User"
              control={control}
              errors={errors.notifyUserReward}
              disabled={
                !defaultValues?.hasReward || defaultValues.rewardCreated
              }
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </div>
  );
};

export default CourseRecordForm;
