import { Box, Grid } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import * as Yup from 'yup';

import PasswordFormField from 'components/formFields/PasswordFormField2';
import EmailFormField from 'components/formFields/EmailFormField2';
import { CheckboxFormField } from 'components/formFields';
import Button from 'components/buttons/Button';
import Alert from 'components/Alert';

import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useAuthDispatcher } from 'providers/AuthProvider/hooks/useAuthDispatcher';

import useQuery from 'hooks/useQuery';
import useCallService from 'hooks/useCallService';
import httpRoutes from 'utils/httpRoutes';

interface ILoginFormInputs {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Authentication = ({
  redirectUrl = '',
  handleBackToLogin,
}: {
  redirectUrl?: string;
  handleBackToLogin?: () => void;
}) => {
  const location: any = useLocation();

  const query = useQuery();
  const errorMessage = query.get('errorMessage');

  const {
    authState: { authenticated },
  } = useAuthState();
  const { login } = useAuthDispatcher();

  const { callService } = useCallService();

  const navigate = useNavigate();
  // const history = useHistory();

  const from: string = location?.state?.from?.pathname || '/';

  // redirect if logged in
  useEffect(() => {
    if (authenticated) {
      navigate(from, { replace: true });
    }
  }, []);

  const onSubmit = async (values: ILoginFormInputs) => {
    const payload = {
      email: values.email,
      password: values.password,
    };

    try {
      const { response } = await callService({
        resource: httpRoutes.authentication.loginRequest(payload),
      });

      if (response) {
        login(response);

        let _redirectUrl = from;

        if (redirectUrl) {
          _redirectUrl = `/${redirectUrl}`;
        }

        navigate(_redirectUrl, { replace: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const goToForgotPassword = () => navigate('/forgot-password');

  const goToSignup = () => {
    if (handleBackToLogin) {
      return handleBackToLogin();
    }
    navigate('/signup');
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().max(255).required('Password is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ILoginFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid item xs={12}>
        {errorMessage && !authenticated && (
          <Alert severity="error" text={errorMessage} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '40px',
            lineHeight: '130%',
          }}
        >
          Log in
        </Box>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px%',
          }}
        >
          Enter your credentials to continue
        </Box>
      </Grid>
      <Grid xs={12} item>
        <EmailFormField
          name="email"
          control={control}
          placeholder="Email"
          errors={errors.email}
          margin="dense"
        />
      </Grid>
      <Grid item xs={12} mb={2}>
        <PasswordFormField
          name="password"
          control={control}
          placeholder="Password"
          errors={errors.password}
          margin="dense"
        />
      </Grid>
      <Grid mb={3} container>
        <Grid xs={6} item>
          <CheckboxFormField
            sx={{
              '.MuiFormControlLabel-label': {
                fontFamily: 'Raleway',
                fontWeight: '600',
              },
              color: 'white',
              '&.Mui-checked': {
                color: 'white',
              },
            }}
            name="rememberMe"
            size="small"
            control={control}
            label="Remember me"
            errors={errors.rememberMe}
          />
        </Grid>
        <Grid
          xs={6}
          item
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '150%',
              color: '#56C596',
              cursor: 'pointer',
            }}
            onClick={goToForgotPassword}
          >
            Forgot your password?
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} mb={1}>
        <Button
          type="submit"
          label="Log in"
          disabled={isSubmitting}
          sx={{
            textTransform: 'uppercase',
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '24px',
            background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
          }}
        />
      </Grid>
      <Grid container mt={5}>
        <Grid item xs={12} mb={1}>
          <Box
            sx={{
              color: 'white',
              textAlign: 'center',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '32px',
            }}
          >
            Don’t have an account?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            label="Sign up"
            onClick={goToSignup}
            disabled={isSubmitting}
            sx={{
              textTransform: 'uppercase',
              fontFamily: 'Raleway',
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '24px',
              background:
                'linear-gradient(180deg, #113145 0%, #113145 131.25%)',
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Authentication;
