import React from 'react';

import * as Yup from 'yup';

import { useFormContext } from 'react-hook-form';

import { Box, Grid, Typography } from '@mui/material';

import {
  DateFormField,
  SelectFormField,
  TextFormField,
} from 'components/formFields';
import { LocationSelector } from '../../../../components/locationSelector/LocationSelector';

const communicationSupportOption = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const daysFromLaunch = [
  { label: '30', value: 30 },
  { label: '60', value: 60 },
  { label: '90', value: 90 },
];

const today = new Date();
today.setHours(0, 0, 0, 0);

export const thirdValidationStep = Yup.object().shape({
  emailBlastDate: Yup.date().when('isEdit', {
    is: false,
    then: Yup.date().min(today, 'Date must be later than today'),
  }),
  socialMediaBlastDate: Yup.date().when('isEdit', {
    is: false,
    then: Yup.date().min(today, 'Date must be later than today'),
  }),
});

const OutReach = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const hasCommunicationSupport = watch('hasCommunicationSupport') === 'yes';
  const outReachCountries = watch('outReachCountries');

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h4">Marketing and Launch Information</Typography>
        </Grid>
      </Grid>
      <Grid item mb={1}>
        <Box
          sx={{
            color: '#1A1A1A',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'italic',
            fontWeight: '500',
          }}
        >
          This is a place to store information about marketing strategy- update
          these after a social media campaign or email to track the efficacy of
          these actions.
        </Box>
      </Grid>

      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h4">Communication Support</Typography>
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <SelectFormField
            name="hasCommunicationSupport"
            control={control}
            label="Has Communication Support?"
            withSelectedOption
            options={communicationSupportOption}
            errors={errors.hasCommunicationSupport}
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <DateFormField
            fullWidth={true}
            name="emailBlastDate"
            errors={errors.emailBlastDate}
            disabled={!hasCommunicationSupport}
            control={control}
            label="Email Blast"
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={2}>
          <DateFormField
            fullWidth={true}
            name="socialMediaBlastDate"
            errors={errors.socialMediaBlastDate}
            disabled={!hasCommunicationSupport}
            control={control}
            label="Social Blast"
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h4">Outreach Activation</Typography>
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={12}>
          <TextFormField
            name="courseOutReachActivationDescription"
            control={control}
            label="Description"
            errors={errors.sampleType}
            placeholder="Text box to write a small description of the “activation/contest” "
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6} mt={2}>
          <LocationSelector
            errors={errors}
            control={control}
            selectedCountryId={outReachCountries}
            multiState
            multiCountry
            stateFieldName={'outReachStates'}
            countryFieldName={'outReachCountries'}
          ></LocationSelector>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateFormField
            fullWidth={true}
            name="courseOutreachStartActivationDate"
            errors={errors.courseOutreachStartActivationDate}
            control={control}
            label="Start Date of Activation"
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <SelectFormField
            name="courseOutReachDaysActive"
            control={control}
            label="# of days from launch it is active"
            withSelectedOption
            options={daysFromLaunch}
            errors={errors.courseOutReachDaysActive}
            margin="dense"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutReach;
