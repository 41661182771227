import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import {
  TextFormField,
  NumberFormField,
  CheckboxFormField,
} from 'components/formFields';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import { useSnackbar } from 'notistack';

import fileManagement from 'utils/s3';

import Dropzone from 'components/dropzone/DropzoneImage';

export type CertificateFormInput = {
  id?: string | undefined;
  url?: string;
  organizationId?: string;
  name?: string;
  fileName: string;
  validity?: number;
  active?: boolean;
  config?: string;
  updateAll?: boolean;
};

export const INITIAL_FORM_STATE: CertificateFormInput = {
  id: undefined,
  url: '',
  organizationId: '',
  name: '',
  fileName: '',
  validity: 0,
  active: true,
  config: '',
  updateAll: false,
};

const INITIAL_FILE_STATE: File[] = [];

const Form = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: CertificateFormInput;
  onSubmit: (values: CertificateFormInput) => void;
}) => {
  const edit = defaultValues?.id;

  const { hideDialog } = useDialogDispatcher();
  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = useState(INITIAL_FILE_STATE);
  const [fileChanged, setFileChanged] = useState(false);

  const preSubmitUpload = async (values: CertificateFormInput) => {
    // Remove all whitespace from filename
    const fileName = values.fileName.replace(/\s/g, '');
    // Find the last occurrence of '.' to get file extension
    const fileTypeIndex = fileName.lastIndexOf('.');
    // Extract the file extension including the dot
    const fileType = fileName.slice(fileTypeIndex);

    if (fileType !== '.pdf') {
      enqueueSnackbar('File format must be PDF to upload.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    }

    // upload image to S3
    if (files.length > 0) {
      const fileUrl =
        encodeURIComponent('certificate-templates') +
        '/' +
        encodeURIComponent(fileName);

      const response = await fileManagement.putItemWithPresignedUrl({
        body: files[0],
        key: fileUrl,
      });

      if (response) {
        values.url = fileUrl;
      } else {
        enqueueSnackbar('File upload failed!', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    }

    onSubmit(values);
    hideDialog();
  };

  const validationSchema = Yup.object().shape({
    organizationId: Yup.string()
      .max(255)
      .required('Organization name is required'),
    name: Yup.string().max(255).required('A name is required'),
    fileName: Yup.string().max(255).required('A file name is required'),
    validity: Yup.number(),
    active: Yup.boolean(),
    config: Yup.string().required('Styling is required'),
    url: Yup.string(),
    updateAll: Yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CertificateFormInput>({
    mode: 'onBlur',
    defaultValues: defaultValues || INITIAL_FORM_STATE,
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(preSubmitUpload)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Certificate
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} md={12}>
              <TextFormField
                name="name"
                control={control}
                label="Certificate Name*"
                errors={errors.name}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFormField
                name="organizationId"
                control={control}
                label="Organization Name*"
                errors={errors.organizationId}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} sm={8}>
              <TextFormField
                name="fileName"
                control={control}
                label="File Name*"
                errors={errors.fileName}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumberFormField
                name="validity"
                control={control}
                label="How long is it valid?"
                errors={errors.validity}
                margin="dense"
                icon={<span>days</span>}
                position="end"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFormField
                name="config"
                control={control}
                label="Style*"
                errors={errors.config}
                margin="dense"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxFormField
                name="active"
                control={control}
                label="Is certificate active?"
                errors={errors.active}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropzone
                maxFiles={1}
                maxSize={5000000}
                handleAcceptedFiles={(acceptedFiles: File[]) => {
                  setFiles(acceptedFiles);
                  setFileChanged(true);
                }}
                helperText="Drag and drop the certificate PDF here."
              />{' '}
            </Grid>
            {edit && (
              <Grid item xs={12} sm={6} alignContent="center">
                <CheckboxFormField
                  name="updateAll"
                  control={control}
                  label="Should we update all historical certificates?"
                  errors={errors.updateAll}
                  disabled={!fileChanged}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            <SaveOutlinedIcon sx={{ mr: 1 }} /> Save
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
